<template>
    <div class="flex items-center justify-center h-full w-full bg-white">
        <AppIconLoading
            size="28"
            :animate="true"
        />

        <slot />
    </div>
</template>
